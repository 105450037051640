import React from 'react'
import Provider from 'components/Provider'
import Layout from 'layouts/layout'
import SEO from 'components/seo'
import Header from 'components/Topic/StarHeader'
import Ds2022Footer from 'components/Topic2022/Ds2022Footer'
import Button from 'components/Base/Button'
import Panel from 'components/Topic/StarPanel'
import ImageSwiper from 'components/Topic/ImageSwiper'

import 'swiper/swiper-bundle.css'
import { Container } from './index.module.less'

let prizePeopleList = [
  {
    imgLink: require('assets/images/topic/ds2022star/group-people1.png').default,
    title: '数据驱动·领军人物奖',
    desc: '具备非常强的数据驱动意识，对推动企业数据体系建设和数据化进程方面有着主导性的推进作用。',
  },
  {
    imgLink: require('assets/images/topic/ds2022star/group-people2.png').default,
    title: '数据驱动·菁英人物奖',
    desc: '在数据分析及数据驱动方面有着丰富的场景化落地经验并取得显著的对实际业务助推的成果。',
  },
]

let prizePeopleBigList1 = [
  {
    imgLink: require('assets/images/topic/ds2021star/group6.png').default,
    title: '数据驱动·行业领军企业奖',
    desc: '在行业数字化转型和数字化经营上，具备很强的行业引领作用。',
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group1.png').default,
    title: '数据驱动·行业创新企业奖',
    desc: '在行业数据驱动和创新应用上具备新锐的应用场景和实践案例。',
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group4.png').default,
    title: '数据驱动·信创实践先锋企业奖',
    desc: '具备非常强的信创实践意识，在信创实践落地上有着行业领先作用。',
  },
  {
    imgLink: require('assets/images/topic/ds2021star/group3.png').default,
    title: '数字驱动·营销创新企业奖',
    desc: '在数字营销领域具有创新性、执行性、可持续性，实现显著商业价值。',
  },
]

let processList = [
  {
    title: '征集',
    time: '2022.09.21 — 2022.10.10',
  },
  {
    title: '评审',
    time: '2022.10.11 — 2022.10.12',
  },
  {
    title: '公示',
    time: '2022.10.19 — 2022.10.21',
  },
  {
    title: '颁奖',
    time: '2022.10.26',
  },
]

let juriesList = [
  '中国信通院泰尔终端实验室\n信息安全部副主任',
  '国家工业信息安全发展研究中心\n信息政策所副所长',
  '全国信息安全技术\n标准工作委员会 副秘书长',
  '北京信息化协会\n信息技术应用创新工作委员会 秘书长',
  '清华大学\n大数据国家工程实验室 总工',
  '北京邮电大学\n信息安全学院 教授',
  '经开区国家信创园\n产业促进部部长',
]

let imageList = [
  require('assets/images/topic/ds2022star/img1.png').default,
  require('assets/images/topic/ds2022star/img2.png').default,
  require('assets/images/topic/ds2022star/img3.png').default,
  require('assets/images/topic/ds2022star/img4.png').default,
  require('assets/images/topic/ds2022star/img5.png').default,
  require('assets/images/topic/ds2022star/img6.png').default,
  require('assets/images/topic/ds2022star/img7.png').default,
]

const DS2022Star: React.FC = () => {
  return (
    <Layout>
      <Provider>
        <div className={Container}>
          <SEO
            title="神策数据｜2022星斗奖典礼｜神策数据驱动大会"
            description="星斗奖的诞生，旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者，通过数据驱动大会这一平台共同分享典型案例成果"
            keywords="星斗奖,神策数据星斗奖,2022数据驱动大会,神策数据驱动大会"
            saTitle="首页-2022数据驱动大会-星斗奖"
          />
          <Header />
          <main>
            <div className="banner-wrap">
              <div className="banner-main-content">
                <div className="banner-text-content">
                  <div className="bt-title">星斗奖</div>
                  <div className="bt-title-two">数据如浩瀚繁星，驱动人类勇往前行</div>
                  <div className="bt-text">神策 2022 数据驱动大会</div>
                  <div className="bt-text-small">Sensors Data-driven Conference 2022</div>
                  <div className="bt-button-content">
                    <Button btnType="blue" href="http://sensorsform.mikecrm.com/vMFwiYm" target="_blank">
                      企业报名
                    </Button>
                    <Button
                      className="bt-margin"
                      btnType="blue"
                      plain
                      href="http://sensorsform.mikecrm.com/8CB8YvP"
                      target="_blank"
                    >
                      个人报名
                    </Button>
                  </div>
                </div>
                <div className="banner-img-content">
                  <img
                    className="banner-img"
                    src={require('assets/images/topic/ds2022star/banner-left.png').default}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <Panel title="星斗奖简介">
              <div className="about-us-content">
                <div className="about-us-text">
                  <div>“帮助中国三千万企业重构数据根基，实现数字化经营”是神策数据的企业愿景，七年历练与成长让神策在中国大数据领域稳固扎根，并致力于将数据驱动理念与实操经验传播分享至各个行业企业，已为超过 2000 家行业头部企业提供了数字化服务。</div>
                  <div><span>星斗奖的诞生旨在鼓励走在数据驱动实践前沿的企业机构及突出贡献者</span>，通过数据驱动大会这一平台更积极地传播交流数据驱动行业发展的新态势，共同分享典型案例成果，进而推动整个中国大数据基础建设与行业应用进程。</div>
                  <div>2017–2021 年，已历经五届星斗奖的评选，近千家在数据驱动前沿的各行业头部企业参与和获得了星斗奖。2022 年 10 月，<span>神策数据携手各位评审专家</span>即将进行第六届星斗奖的评选和颁奖。</div>
                </div>
                <div className="about-us-img">
                  <ImageSwiper dataSource={imageList} />
                </div>
              </div>
            </Panel>
            <Panel title="历届评审专家团队">
              <div className="juries-wrap">
                <div className="juries-content">
                  {juriesList?.map((ele, index) => {
                    return (
                      <div className="juries-content-every" key={index}>
                        <img
                          src={require('assets/images/topic/ds2022star/juries_icon.svg').default}
                          alt=""
                          className="img-pc"
                        ></img>
                        <img
                          src={require('assets/images/topic/ds2022star/juries_mb_icon.svg').default}
                          alt=""
                          className="img-mb"
                        ></img>
                        <div>
                          <pre>{ele}</pre>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Panel>
            <Panel title="星斗奖奖项设置">
              <div className="prize-content primary-big">
                <div className="prize-title">年度数据驱动先锋企业</div>
                <div className="prize-detail">
                  {prizePeopleBigList1?.map((ele, idx) => {
                    return (
                      <div key={idx} className="prize-detail-every">
                        <img src={ele.imgLink} alt="" />
                        <div className="prize-no-content">
                          <div className="prize-detail-title">{ele.title}</div>
                          <div className="prize-detail-desc-sencond">{ele.desc}</div>
                          <Button
                            className="prize-detail-button pc-content"
                            btnType="blue"
                            size="small"
                            href="http://sensorsform.mikecrm.com/vMFwiYm"
                            target="_blank"
                          >
                            立即报名
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                  <div className="phone-content button-center-content">
                    <Button
                      className="prize-detail-button"
                      btnType="blue"
                      size="small"
                      href="http://sensorsform.mikecrm.com/8CB8YvP"
                      target="_blank"
                    >
                      立即报名
                    </Button>
                  </div>
                </div>
              </div>
              <div className="prize-content">
                <div className="prize-title">年度数据驱动先锋人物</div>
                <div className="prize-detail">
                  {prizePeopleList?.map((ele, idx) => {
                    return (
                      <div key={idx} className="prize-detail-every prize-detail-every-first">
                        <img src={ele.imgLink} alt="" />
                        <div className="prize-no-content">
                          <div className="prize-detail-title">{ele.title}</div>
                          <div className="prize-detail-desc">{ele.desc}</div>
                          <Button
                            className="prize-detail-button pc-content"
                            btnType="blue"
                            size="small"
                            href="http://sensorsform.mikecrm.com/8CB8YvP"
                            target="_blank"
                          >
                            立即报名
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                  <div className="phone-content button-center-content">
                    <Button
                      className="prize-detail-button"
                      btnType="blue"
                      size="small"
                      href="http://sensorsform.mikecrm.com/8CB8YvP"
                      target="_blank"
                    >
                      立即报名
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel className="margin-style-title" title="奖项征集流程">
              <div className="process-detail">
                {processList?.map((ele, index) => {
                  return (
                    <div className="process-detail-every-content" key={index}>
                      {index === 0 && <div className="process-detail-first"></div>}
                      <div className="process-detail-every">
                        <img src={require('assets/images/topic/ds2022star/node.png').default} alt=""></img>
                        <div className="process-detail-title">{ele.title}</div>
                        <div className="process-detail-time">{ele.time}</div>
                      </div>
                      {index + 1 < processList.length ? (
                        <div className="process-empty" style={index === 0 ? { left: 104 } : {}} />
                      ) : (
                        <div className="process-detail-end"></div>
                      )}
                    </div>
                  )
                })}
              </div>
            </Panel>
          </main>
          <Ds2022Footer title="扫码咨询大会更多信息" />
        </div>
      </Provider>
    </Layout>
  )
}

export default DS2022Star
