import React, { useState } from 'react'
import classnames from 'classnames'
import './index.module.less'

interface TopicHeaderProps {
  className?: string
  hideMenu?: boolean
}

const StarHeader: React.FC<TopicHeaderProps> = (params) => {
  const { className, hideMenu = false } = params
  const [spread, setSpread] = useState(false)

  return (
    <header className={classnames('star-header', className)}>
      <nav className="star-header--nav">
        <a href="/auto" className="star-header--logo">
          <img
            src={require('assets/images/topic/ds2022star/title_logo.png').default}
            alt="logo"
            className="icon-logo"
          />
        </a>
        {!hideMenu && (
          <>
            <div className="navbar-toggle" onClick={() => setSpread(!spread)}>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </div>
            <ul className={classnames('star-header--menu', { open: spread })}>
              <li>
                <a href="/topic/ds2022.html">
                  <span>大会首页</span>
                  <img src={require('assets/images/topic/ds2022star/home_icon.svg').default} alt="icon"></img>
                </a>
              </li>
              <li>
                <a href="https://www.sensorsdata.cn/">
                  <span>官网首页</span>
                  <img src={require('assets/images/topic/ds2022star/home_icon.svg').default} alt="icon"></img>
                </a>
              </li>
            </ul>
          </>
        )}
      </nav>
    </header>
  )
}

export default StarHeader
