import React from 'react'
import classnames from 'classnames'
import './index.module.less'

interface TopicPanelProps {
  className?: string
  title?: string
  id?: any
}

const StarPanel: React.FC<React.PropsWithChildren<TopicPanelProps>> = (params) => {
  const { className, children, title, id } = params

  const cnames = classnames('star-panel', className)

  return (
    <section id={id} className={cnames}>
      <div className="star-panel--title">
        <div className="star-panel--title-left"></div>
        <h2 className="star-panel--title_cn">{title}</h2>
      </div>
      <article className="star-panel--body">{children}</article>
    </section>
  )
}

export default StarPanel
