import React from 'react'
import classnames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { isPc } from 'utils/utils'

interface Ds2022FooterPropsState {
  className?: string
  title?: string
  message?: string | React.ReactNode
  code?: string
}

const Ds2022Footer: React.FC<Ds2022FooterPropsState> = ({ className, code, title, message }) => {
  return (
    <footer className={classnames('mt-[60px] lg:mt-[100px] ', className)}>
      <div className="text-center">
        <div>
          {/* <img className="w-[136px] lg:w-[242px] lg:h-[242px] " src={code} alt="" /> */}
          <StaticImage
            className="w-[136px] lg:w-[242px] lg:h-[242px] "
            src="https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/zixun_qrcode.png"
            alt=""
          />
        </div>
        <p className="mt-[20px] lg:mt-[25px] text-[#fff] text-[14px] lg:text-[24px] font-medium">{title}</p>
      </div>
      <div className="bg-[#000000] text-[#fff] leading-[44px] lg:leading-[60px]  opacity-[0.6] mt-[80px] text-center lg:text-[12px] text-[14px]">
        <span className="inline lg:hidden"> 神策网络科技（北京）有限公司</span>
        <span className="text-[14px] px-[6px]  lg:inline hidden ">
          {
            <>
              {' '}
              © 2021 神策数据 <a href="//beian.miit.gov.cn">京ICP备15055677号-1</a>
            </>
          }
        </span>
        <span className="text-[14px] px-[6px] lg:inline hidden ">
          {
            <a href="//beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
              <img src="//sensorsdata.cn/assets/img/website_filling_d0289dc.jpg" alt="website" /> 京公网安备
              11010802027887号
            </a>
          }
        </span>
        <span className="text-[14px] px-[6px] lg:inline hidden ">
          <a
            href="https://sensorsdata.cn/assets/figure/pdf/2022/%E7%A5%9E%E7%AD%96%E6%95%B0%E6%8D%AE%E5%B9%BF%E6%92%AD%E7%94%B5%E8%A7%86%E8%8A%82%E7%9B%AE%E5%88%B6%E4%BD%9C%E7%BB%8F%E8%90%A5%E8%AE%B8%E5%8F%AF%E8%AF%812022221.pdf"
            target="_blank"
          >
            广播电视节目制作经营许可证（京）字第22320号
          </a>
        </span>
      </div>
    </footer>
  )
}
export default Ds2022Footer
